import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { createColumnHelper } from "@tanstack/react-table";
import React, { HTMLProps, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import AppCustomTable from "../../AppComponents/AppCustomTable";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm, AppFormField } from "../../AppComponents/Forms";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { NoDataBox } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppModel from "../../Hooks/useModel/AppModel";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import {
  ContactListingPagination,
  getContactHistoryData,
  getContactHistoryError,
  getContactHistoryLoading,
} from "../../Store/Slices/Contacts/contacts.selector";
import { editId } from "../../Store/Slices/Contacts/contacts.slice";
import HistoryArrowIcon from "../../assets/images/historyarrow.png";
import NoDataImage from "../../assets/images/noDatafound.svg";
import { ReactComponent as NotesIcon } from "../../assets/images/icons/notes-small.svg";
import { ReactComponent as SaveIcon } from "../../assets/images/save-icon.svg";
import { ReactComponent as TagCrossIcon } from "../../assets/images/tag-cross-icon.svg";
import { ActionPopover } from "../Broadcast/Email/Template/EmailTemplateListingStyle";
import AppSkeletonTableLoader from "../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import { LeadPageWrapper } from "./LeadsStyles";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { RenderArrayValues } from "../../Utils/tableUtils/TableUtils";
import ChangeLifeCycleStage from "./contact-action/change-lifecycle-stage";
import ChangeNotes from "./contact-action/change-notes";
import EmailSingleBroadcast from "./contact-action/email-single-broadcast";
import SmsSingleBroadcast from "./contact-action/sms-single-broadcast";
import WhatsappSingleBroadcast from "./contact-action/whatsapp-single-broadcast";
interface BasicInfo {
  first_name: string;
  last_name: string;
  designername: string;
  email: string;
  emptyemail: string;
  phone: string;
  workphone: string;
  birthdate: string;
  facebook: string;
  twitter: string;
  linkdin: string;
  hasUpdateAceess?: string;
  sorting?: any;
  hidePagination?: boolean;
  removeContactDetailsLink?: boolean;
  totalCount?: any;
  sortingApply?: boolean;
  type?: string;
  hideSelection?: boolean;
}
export const LeadTable: React.FC<any> = ({
  data,
  toggleModal2,
  dynamicsColumn,
  setLocalValues,
  localValues,
  setShouldApply,
  toggleContactModal,
  selectedDocumentIds,
  setSelectedDocumentIds,
  NodataButton,
  createImportDoc,
  setSelectAll,
  selectAll,
  sorting,
  hasUpdateAceess,
  loader,
  hidePagination,
  removeContactDetailsLink,
  totalCount,
  sortingApply,
  leadRefresh,
  type,
  hideSelection,
}) => {
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userConfig } = useAppSelector(useAuthStore);
  const ContactHistoryData = useSelector(getContactHistoryData);
  const isContactHistoryLoading = useSelector(getContactHistoryLoading);
  const isErrorInContactHistoryAPI = useSelector(getContactHistoryError);
  const paginationCount = useAppSelector(ContactListingPagination);
  const [dynamicsColumns, setDynamicsColumns] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [basicInfo] = useState<BasicInfo>({
    first_name: "Shrikant",
    last_name: "Dange",
    designername: "Graphics Designer",
    email: "shrikant.dange@srvmedia.com",
    emptyemail: "-",
    phone: "",
    workphone: "",
    birthdate: "",
    facebook: "",
    twitter: "",
    linkdin: "",
  });

  const [actionDrawerChildComponent, setActionDrawerChildComponent] =
    useState<any>(null);

  const getOrdinal = (number: number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  function formatDateToHTML(dateString: string) {
    // Create a new Date object from the given string
    const date = new Date(dateString);

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year using local time methods
    const day = date.getDate(); // Get local day
    const monthIndex = date.getMonth(); // Get local month index
    const year = date.getFullYear(); // Get local year
    let hours = date.getHours(); // Get local hours
    const minutes = date.getMinutes(); // Get local minutes

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date as HTML string
    const formattedHTML = (
      <Box>
        <Box className="date-box">
          <div className="day">{day}</div>
          <div className="month-year">
            <span style={{ display: "block", fontWeight: "500" }}>
              {monthNames[monthIndex]}
            </span>
            <span style={{ display: "block" }}>{year}</span>
          </div>
        </Box>
        <div className="time">
          {hours < 10 ? "0" + hours : hours}:
          {minutes < 10 ? "0" + minutes : minutes} {ampm}
        </div>
      </Box>
    );

    return formattedHTML;
  }

  const toggleDrawerNew = (contactId: any) => {
    setIsOpen(true);
    let payload;
    if (contactId) {
      payload = {
        selectedColumns: [
          "referrerName",
          "module",
          "referrerUrl",
          "campaignName",
          "deviceType",
          "channel",
          "updatedAt",
          "campaignSource",
        ],
        contact_id: contactId.toString(),
      };
    }
    dispatch(ContactsActions.leadsForContact(payload));
  };
  // message
  const checkAllSelect: boolean =
    paginationCount?.totalRecords < localValues?.perPage
      ? false
      : selectedDocumentIds?.length === data?.length
      ? true
      : false;

  const useUpDateContactModal = () => {
    const { isShowing, toggle } = useModal();
    return { isUpdateShowingModal: isShowing, toggleUpdateContact: toggle };
  };
  const { isUpdateShowingModal, toggleUpdateContact } = useUpDateContactModal();

  // getcontact by id*********************

  const getContactById = (contact_id: any) => {
    dispatch(ContactsActions.getEditContactsFilds(contact_id))
      .then((res: any) => {
        if (res?.payload?.data?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error",
          );
        } else {
          dispatch(editId(contact_id));
          toggleContactModal();
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const colorPairs = [
    {
      backgroundColor: "#FFE6D1",
      color: "#9F826A",
    },
    {
      backgroundColor: "#D1FAFF",
      color: "#5B8E95",
    },
    {
      backgroundColor: "#FFE2E7",
      color: "#AA747D",
    },
    {
      backgroundColor: "#D9FFE4",
      color: "#63B078",
    },
    {
      backgroundColor: "#FBFFD1",
      color: "#9AA241",
    },
  ];
  let prev = 0;
  const getColors = (word: string) => {
    if (!word || typeof word !== "string") {
      return {
        bg: "#FFE6D1",
        textColor: "#9F826A",
      };
    }
    const firstLetter = word.charAt(0);

    // Convert the letter to lowercase to handle both uppercase and lowercase letters
    const lowerCaseLetter = firstLetter.toLowerCase();

    // Check if the character is a valid lowercase letter
    if (/^[a-z]$/.test(lowerCaseLetter)) {
      // Get the ASCII code of the letter and subtract the ASCII code of 'a' to get the position
      const position = lowerCaseLetter.charCodeAt(0) - "a".charCodeAt(0) + 1;
      let colorIndex = position % colorPairs.length;
      if (prev === colorIndex) {
        prev = colorIndex + 1;
      } else {
        prev = colorIndex;
      }
      return colorPairs[prev];
    } else {
      return {
        bg: "#FFE6D1",
        textColor: "#9F826A",
      };
    }
  };
  const FirstColumn = (original: any, firstObjectKey: any) => {
    return original?.first_name && original?.last_name
      ? `${original?.first_name} ${original?.last_name}`
      : original?.first_name
      ? original?.first_name
      : original?.last_name
      ? original?.last_name
      : original?.email &&
        (original?.[firstObjectKey]?.country_code ||
          original?.[firstObjectKey]?.extension)
      ? `${original?.email} ${
          original?.[firstObjectKey]?.country_code ||
          original?.[firstObjectKey]?.extension +
            original?.[firstObjectKey]?.number
        }`
      : original?.email
      ? original?.email
      : original?.[firstObjectKey]?.country_code ||
        original?.[firstObjectKey]?.extension
      ? (original?.[firstObjectKey]?.country_code ||
          original?.[firstObjectKey]?.extension) +
        original?.[firstObjectKey]?.number
      : "-";
  };

  //  columns ============**********
  const nameTooltipComponent = (elm: any, index: number) => {
    return columnHelper.accessor(elm?.name, {
      header: headerName(elm?.label, elm),
      id: elm?.name,
      size: 320,
      minSize: 320,
      cell: (info: any) => {
        const original = info?.cell?.row?.original;
        let firstObjectKey: any = null;
        if (!(original?.first_name || original?.last_name || original?.email)) {
          for (const key in original) {
            if (
              typeof original[key] === "object" &&
              original[key] !== null &&
              key !== "contact_tool_tip"
            ) {
              firstObjectKey = key;
              break;
            }
          }
        }
        return (
          <NameWrapper>
            <AppToolTip
              tooltipProps={{
                placement: "right",
              }}
              iconButtonProps={{
                disableRipple: true,
                style: { padding: 0 },
              }}
            >
              <div
                className="avatar long-word"
                title={FirstColumn(original, firstObjectKey) || "-"}
                style={{
                  display: "flex",
                  fontSize: "14px",
                  gap: "1rem",
                  alignItems: "center",
                  fontWeight: "400",
                  cursor: "pointer",
                  color: removeContactDetailsLink
                    ? theme.palette.primary.main
                    : theme.palette.default.black,
                }}
                onClick={() =>
                  removeContactDetailsLink
                    ? {}
                    : navigate(
                        `/contacts/view/${info.cell.row.original?.id}/basic-information`,
                      )
                }
              >
                <Avatar
                  alt={
                    original?.first_name ||
                    original?.last_name ||
                    original?.email ||
                    ""
                  }
                  src=""
                  sx={getColors(
                    original?.first_name ||
                      original?.last_name ||
                      original?.email,
                  )}
                  style={{ width: "25px", height: "25px", fontSize: "13px" }}
                >
                  {original?.first_name?.charAt(0) || "G"}
                </Avatar>
                <span
                  style={
                    !removeContactDetailsLink
                      ? {
                          textDecoration: "underline",
                          fontSize: "13px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }
                      : {
                          fontSize: "13px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: theme.palette.default.black,
                        }
                  }
                >
                  {FirstColumn(original, firstObjectKey) || "-"}
                </span>
              </div>
            </AppToolTip>
            {<EditDelete id={info} />}
          </NameWrapper>
        );
      },
    });
  };

  const EditDelete: React.FC<any> = ({ id }) => {
    const rowItem = id.cell.row.original;
    const rowId = id.cell.row.original.id;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleDetails = (id: string) => {
      setTimeout(() => {
        // navigate(`/bot/${bot_id}/contact-details/${id}`);
        getContactById(id);
      }, 100);
    };
    const deleteRow = () => {
      toggleModal2();
      // setSelectedId(rowId);
      dispatch(editId(rowId));
    };

    const handleIconAction = (
      e: any,
      iconType: "whatsapp" | "email" | "sms" | "lifecycle-stage" | "notes",
    ) => {
      if (!rowItem?.can_update) return;
      setActionDrawerChildComponent({ type: iconType, id: rowId });
    };

    return (
      <div className="buttonWrapper">
        {userConfig?.payload?.email_enabled ? (
          <IconButton
            aria-describedby={id}
            onClick={(e: any) => handleIconAction(e, "email")}
            title="Email"
          >
            <EmailOutlinedIcon />
          </IconButton>
        ) : null}
        {userConfig?.payload?.sms_enabled ? (
          <IconButton
            aria-describedby={id}
            onClick={(e: any) => handleIconAction(e, "sms")}
            title="Sms"
          >
            <SmsOutlinedIcon />
          </IconButton>
        ) : null}
        {userConfig?.payload?.whatsapp_enabled ? (
          <IconButton
            aria-describedby={id}
            onClick={(e: any) => handleIconAction(e, "whatsapp")}
            title="Whatsapp"
          >
            <WhatsAppIcon />
          </IconButton>
        ) : null}

        <IconButton
          aria-describedby={id}
          onClick={(e: any) => handleIconAction(e, "lifecycle-stage")}
          title={"Change Lifecycle Stage "}
        >
          <ChangeCircleOutlinedIcon />
        </IconButton>
        <IconButton
          aria-describedby={id}
          onClick={(e: any) => handleIconAction(e, "notes")}
          title={"Manage Notes"}
        >
          <NotesIcon />
        </IconButton>

        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)}>
          <AppMaterialIcons iconName="MoreVert" />
        </IconButton>
        <ActionPopover
          id={id}
          name="popup"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List>
            {rowItem?.can_update ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleDetails(rowId);
                    handleClose();
                  }}
                >
                  Edit
                </ListItemButton>
              </ListItem>
            ) : null}

            {rowItem?.can_delete ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    deleteRow();
                    handleClose();
                  }}
                >
                  {/* <DeleteContact>
                  <DeleteIcon />
                </DeleteContact> */}
                  Delete
                </ListItemButton>
              </ListItem>
            ) : null}

            {/* <ListItem disablePadding>
              <ListItemButton
              onClick={() => {
                  handleRedirect(rowId);
              }}
              >
                Recuring Visits Page
              </ListItemButton>
            </ListItem> */}

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDrawerNew(rowId);
                  handleClose();
                }}
              >
                Recurring Visits
              </ListItemButton>
            </ListItem>
          </List>
        </ActionPopover>
      </div>
    );
  };
  const columnHelper = createColumnHelper<any>();
  let idColumn: any = null; // Variable to store the column with "id" header
  let temp: any = [];

  const otherColumns = (data: any, name: any) => {
    // const regex =
    //   /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (0[1-9]|[12][0-9]|3[01]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4} \d{2}:\d{2}:\d{2} GMT$/;
    // if (typeof data[name] === "string" && regex.test(data[name])) {
    //   return moment(data[name]).format("ddd, DD MMM, YYYY hh:mm A");
    // }
    return data[name] && data[name]?.number
      ? "+ " +
          (data[name]?.country_code
            ? data[name]?.country_code
            : data[name]?.extension) +
          " " +
          data[name]?.number
      : Array.isArray(data[name]) && data[name]?.[0]?.label
      ? data[name]?.map((item: any) => item.label).join(", ")
      : Array.isArray(data[name])
      ? RenderArrayValues(data[name])
      : data[name]
      ? data[name]
      : "-";
  };

  const renderColumnData = (info: any, elm: any) => {
    let data = info.cell.row.original;
    return (
      <span
        className={`long-word ${
          Array.isArray(data[elm?.name]) ? "overflow-visible" : ""
        }`}
        title={
          Array.isArray(data[elm?.name]) ? "" : otherColumns(data, elm?.name)
        }
      >
        <span>{otherColumns(data, elm?.name) || "-"}</span>
      </span>
    );
  };
  let headerName = (label: any, elm: any) => {
    if (localValues?.sort_by === 1 && localValues?.sort_field === elm?.name) {
      return label;
    } else if (
      localValues?.sort_by === -1 &&
      localValues?.sort_field === elm?.name
    ) {
      return label;
    } else {
      return label;
    }
  };
  const CreateCoulumns = () => {
    if (dynamicsColumn?.length !== 0) {
      dynamicsColumn?.forEach((elm: any, index: number) => {
        let colData;
        if (elm?.name !== "id" && elm?.name !== "name") {
          colData = columnHelper.accessor(elm?.name, {
            header: headerName(elm?.label, elm),
            id: elm?.name,
            cell: (info: any) => renderColumnData(info, elm),
            size: 190,
            minSize: 190,
          });
          temp.push(colData);
        } else {
          if (elm?.name === "name") {
            let tollTip: any = nameTooltipComponent(elm, index);
            temp?.push(tollTip);
          }
        }
        if (elm?.name === "id") {
          idColumn = columnHelper.accessor(elm?.name, {
            id: "select",
            size: 10,
            maxSize: 10,
            header: ({ table }) => (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                {...{
                  checked: row?.getIsSelected(),
                  disabled: !row?.getCanSelect(),
                  indeterminate: row?.getIsSomeSelected(),
                  onChange: row?.getToggleSelectedHandler(),
                }}
              />
            ),
            footer: (info: any) => info.column.id,
          });
        }
      });
      temp.unshift(idColumn);
      setDynamicsColumns(temp);
    }
  };

  useEffect(() => {
    if (dynamicsColumn && dynamicsColumn?.length > 0) {
      CreateCoulumns();
    }
  }, [data, dynamicsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePageChange(event: any, value: any) {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        currentPage: value - 1,
      });
    // setShouldApply(true);
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        perPage: value,
      });
    // setShouldApply(true);
  };
  const getSortData = (data: any) => {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        sort_field: data[0]?.id,
        sort_by: data[0]?.desc ? -1 : 1,
        defaultApply: true,
      });
  };

  return (
    <React.Fragment>
      {!data?.length && !loader ? (
        <Box
          sx={{
            height: "calc(100vh - 137px)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContactNodata
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "4rem 2rem 2rem 2rem",
              boxShadow: "none !important",
              backgroundColor: "white",
            }}
          >
            <Paper
              sx={{
                width: "40%",
                mb: 2,
                boxShadow: "none !important",
                backgroundColor: "transparent",
              }}
            >
              <NoDataBox>
                <img src={NoDataImage} alt="no data available" />
                <p>Contact Not Found</p>
                {type === "segment" ? null : (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AppButton
                      style={{ margin: "1rem" }}
                      variant="contained"
                      startIcon="add"
                      onClick={() => toggleContactModal()}
                    >
                      Add Contact
                    </AppButton>
                    <span>Or</span>
                    <AppButton
                      style={{ margin: "1rem" }}
                      variant="contained"
                      startIcon="add"
                      onClick={() => createImportDoc()}
                    >
                      Import Contact
                    </AppButton>
                  </Box>
                )}
              </NoDataBox>
            </Paper>
          </ContactNodata>
        </Box>
      ) : (
        <React.Fragment>
          <div className="data-container">
            {checkAllSelect ? (
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#cfe8f1",
                  display:
                    hasUpdateAceess === "global_access" ? "block" : "none",
                }}
              >
                <>
                  {selectAll
                    ? "Clear selection"
                    : `${selectedDocumentIds?.length} contacts selected`}
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      setSelectedDocumentIds((prevState: any) => {
                        if (selectAll) {
                          setSelectAll(false);
                          return false;
                        } else {
                          setSelectAll(true);
                          return prevState;
                        }
                      });
                    }}
                  >
                    {selectAll
                      ? `${
                          totalCount
                            ? totalCount
                            : paginationCount?.totalRecords
                        } contacts selected.`
                      : `Select all ${
                          totalCount
                            ? totalCount
                            : paginationCount?.totalRecords
                        } contacts ?`}
                  </Button>
                  <IconButton
                    size="small"
                    style={{ color: theme.palette.default.black }}
                    disableRipple={true}
                    onClick={() => {
                      setSelectedDocumentIds(false);
                      setSelectAll(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              </div>
            ) : null}
            {/* table */}
            <AppCustomTable
              data={data}
              dynamicsColumns={dynamicsColumns}
              setSelectedRow={setSelectedDocumentIds}
              selectedRow={selectedDocumentIds}
              getSortData={getSortData}
              loader={loader}
              removeContactDetailsLink={removeContactDetailsLink}
              sortingApply={sortingApply}
              hideSelection={hideSelection}
            />
          </div>

          {!hidePagination && data && data?.length ? (
            <AppPaginationComponent
              totalCount={paginationCount?.totalRecords}
              handlePageChange={handlePageChange}
              currentPage={localValues?.currentPage}
              totalPages={paginationCount?.totalPages}
              rowsPerPage={localValues?.perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              title="Contacts"
            />
          ) : null}
        </React.Fragment>
      )}

      {/* basic inforamtion edit */}
      <AppModel
        isShowing={isUpdateShowingModal}
        onClose={() => toggleUpdateContact()}
        divStyle={{
          width: "671px",
          //   height:'371px',
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <LeadPageWrapper>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Basic Information</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => toggleUpdateContact()}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>

          <AppForm
            initialValues={basicInfo}
            onSubmit={(values: any, submitProps: any) => {
              // handleSubmitBtn(values, submitProps);
            }}
          >
            <div className="basic-info-popup">
              <div className="fname">
                <div className="input-field">
                  <AppFormField
                    name={"firstname"}
                    label="First Name"
                    // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                  />
                </div>
                <div className="input-field">
                  <AppFormField name={"last_name"} label="Last Name" />
                </div>
              </div>
              <div className="input-field">
                <AppFormField name={"designername"} label="Job title" />
              </div>

              <div className="email-verification">
                <div className="input-field-email">
                  <AppFormField name={"email"} label="Email" />
                </div>
                <div className="verify-email-button">
                  <AppButton variant="outline">Verify email</AppButton>
                </div>
              </div>

              <div className="input-field">
                <AppFormField name={"emptyemail"} label="Email" />
              </div>
              <div className="input-field">
                <AppFormField name={"phone"} label="Phone" />
              </div>

              <div className="input-field">
                <AppFormField name={"workphone"} label="work Phone" />
              </div>
              <div className="input-field">
                <AppFormField name={"birthdate"} label="D. O. B." />
              </div>
            </div>

            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleUpdateContact()} variant="grey">
                cancel
              </AppButton>
              <AppButton type="submit">
                <IconButton>
                  <SaveIcon />
                </IconButton>
                save
              </AppButton>
            </div>
          </AppForm>
        </LeadPageWrapper>
      </AppModel>

      {/* Drawer */}
      {isOpen && (
        <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
          <Box
            p={2}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: "#EEF1FF",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              fontSize={"16px"}
              fontWeight={500}
            >
              Recurring Visits
            </Typography>
            <Box onClick={() => setIsOpen(false)} sx={{ cursor: "pointer" }}>
              <AppMaterialIcons iconName="Close" />
            </Box>
          </Box>
          <Box
            sx={{
              width: "80vw",
              p: 3,
              height: "calc(100% - 78px)",
              overflow: "auto",
            }}
            role="presentation"
          >
            {isContactHistoryLoading ? (
              <AppSkeletonTableLoader
                numberOfCol={1}
                numberofRow={10}
                skeletonHeight={30}
                skeletonWidth={"100%"}
                skeletonHeaderHeight={40}
                skeletonHeaderWidth={"100%"}
                tableHeight={`calc(100vh - 128px)`}
              />
            ) : isErrorInContactHistoryAPI ? (
              <NoRecordsFound
                headerText="Something Went Wrong!"
                subText="Error Loading Data.."
                height="calc(100vh - 126px)"
              />
            ) : ContactHistoryData.length === 0 ? (
              <NoRecordsFound height="calc(100vh - 126px)" />
            ) : (
              ContactHistoryData.map((item: any, index: number) => {
                const reverseIndex = ContactHistoryData.length - index;
                const ordinalIndex = getOrdinal(reverseIndex);
                let backgroundColor;
                switch (reverseIndex % 4) {
                  case 1:
                    backgroundColor = "#FFF854";
                    break;
                  case 2:
                    backgroundColor = "#70DDFF";
                    break;
                  case 3:
                    backgroundColor = "#FFBD6F";
                    break;
                  case 0:
                    backgroundColor = "#47FFC8";
                    break;
                  default:
                    backgroundColor = "transparent";
                }
                return (
                  <ListItem disablePadding sx={{ mb: 4 }}>
                    <ContactHitstoyWrapper>
                      <Box className="details-left">
                        {formatDateToHTML(item?.updatedAt)}
                        <Box sx={{ backgroundColor }} className="visit">
                          {ordinalIndex} Visit
                        </Box>
                        <Box textAlign={"center"}>
                          {ordinalIndex === "1st" ? null : (
                            <img
                              width={"12px"}
                              src={HistoryArrowIcon}
                              alt="History Arrow"
                            />
                          )}
                        </Box>
                      </Box>
                      <Box className="history-content_box">
                        <Box className="user-details border-r">
                          <Box
                            mb={"4px"}
                            className="username"
                            title={item?.visitor_name || "N/A"}
                          >
                            {item?.visitor_name || "N/A"}
                          </Box>
                          <Box
                            title={item?.visitor_email || "N/A"}
                            mb={"4px"}
                            className="userText"
                          >
                            {item?.visitor_email || "N/A"}
                          </Box>
                          <Box
                            title={item?.visitor_phone || "N/A"}
                            mb={"4px"}
                            className="userText"
                          >
                            {item?.visitor_phone || "N/A"}
                          </Box>
                        </Box>

                        <Box
                          width={"calc(100% - 210px)"}
                          display={"flex"}
                          alignItems={"center"}
                          pl={3}
                        >
                          <Grid container spacing={3}>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Referrer Name</Box>
                                <Box
                                  className="userText"
                                  title={item?.referrerName || "N/A"}
                                >
                                  {item?.referrerName || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Module</Box>
                                <Box
                                  className="userText"
                                  title={item?.module || "N/A"}
                                >
                                  {item?.module || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Referrer Url</Box>
                                <Box
                                  title={item?.referrerUrl || "N/A"}
                                  className="userText"
                                >
                                  {item?.referrerUrl || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Campaign</Box>
                                <Box
                                  title={item?.campaignName || "N/A"}
                                  className="userText"
                                >
                                  {item?.campaignName || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Device Type</Box>
                                <Box
                                  title={item?.deviceType || "N/A"}
                                  className="userText"
                                >
                                  {item?.deviceType || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Channel</Box>
                                <Box
                                  title={item?.channel || "N/A"}
                                  className="userText"
                                >
                                  {item?.channel || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xl={2} lg={4} xs={6}>
                              <Box className="user-details_2">
                                <Box className="username">Source</Box>
                                <Box
                                  title={item?.campaignSource || "N/A"}
                                  className="userText"
                                >
                                  {item?.campaignSource || "N/A"}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </ContactHitstoyWrapper>
                  </ListItem>
                );
              })
            )}
          </Box>
        </Drawer>
      )}

      <Drawer
        anchor="right"
        open={actionDrawerChildComponent?.type?.length > 0 ? true : false}
        onClose={undefined}
        sx={{
          "& .MuiDrawer-paper": {
            width: "600px",
            padding: "0px",
            overflow: "hidden",
          },
        }}
      >
        {actionDrawerChildComponent?.type === "whatsapp" ? (
          <WhatsappSingleBroadcast
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "email" ? (
          <EmailSingleBroadcast
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "sms" ? (
          <SmsSingleBroadcast
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "lifecycle-stage" ? (
          <ChangeLifeCycleStage
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
        {actionDrawerChildComponent?.type === "notes" ? (
          <ChangeNotes
            actionDrawerChildComponent={actionDrawerChildComponent}
            setActionDrawerChildComponent={setActionDrawerChildComponent}
          />
        ) : null}
      </Drawer>
    </React.Fragment>
  );
};

export default LeadTable;

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <input
      type="checkbox"
      ref={ref}
      className="tanstack-check"
      {...rest}
      style={{ width: "12px", height: "12px" }}
    />
  );
}

const ContactNodata = styled(Box)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: none !important;
  }
`;

const FooterRow = styled("p")`
  font-size: 13px;
  color: ${theme.palette.default.black};
  display: flex;
  align-items: center;
  padding: 5px 15px;
`;
const NameWrapper = styled("div")`
  display: flex;
  align-items: center;
  position: relative;
  // max-width: 250px;
  .buttonWrapper {
    position: absolute;
    right: 0px;
    background: #fff;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
    top: -3px;
    display: none;
    padding: 1px 4px;
    .MuiIconButton-root {
      padding: 6px;
    }
    svg {
      font-size: 15px;
    }
  }
  &:hover {
    .buttonWrapper {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

const ContactHitstoyWrapper = styled("div")`
  && {
    width: 100%;
    gap: 24px;
    display: flex;
    .details-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .visit {
        font-size: 12px;
        border-radius: 2px;
        padding: 6px 8px;
      }
      .time {
        text-align: center;
        margin-top: 4px;
        font-size: 13px;
        color: ${theme.palette.default.darkGrey};
      }
    }
    .date-box {
      max-height: 64px;
      border-radius: 4px;
      min-width: 106px;
      width: 106px;
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
      background-color: ${theme.palette.primary.dark};
      .day {
        font-size: 32px;
        font-weight: 700;
        color: ${theme.palette.default.white};
      }
      .month-year {
        color: ${theme.palette.default.white};
      }
    }
    .history-content_box {
      width: calc(100% - 106px);
      background-color: #fafafb;

      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;

      .border-r {
        border-right: 1px dashed ${theme.palette.default.border};
      }

      .user-details {
        // padding: 16px;
        min-width: 210px;
        max-width: 210px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .username {
          max-width: 190px;
          font-size: 16px;
          font-weight: 500;
          color: ${theme.palette.default.black};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .userText {
          max-width: 190px;
          font-size: 14px;
          color: ${theme.palette.default.darkGrey};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .user-details_2 {
        padding-bottom: 8px;
        // min-width: 210px;
        .username {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
          color: ${theme.palette.default.black};
        }
        .userText {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          color: ${theme.palette.default.darkGrey};
        }
      }
    }
  }
`;
